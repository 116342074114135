import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/common/sidebar";
import useSticky from "../../hooks/use-sticky";
import { menu_bar } from "../../redux/features/header-slice";
import NavMenu from "./nav-menu";
import { useSelector } from "react-redux";
import { getWhiteLabelConfig } from "../../services/whitelabel-config";
import { toast } from "react-toastify";
import { menu_data } from "../../data";
import student_menu_data from "../../data/student-menu-data";
import recuiter_menu_data from "../../data/recuiter-menu-data";
import mentor_menu_data from "../../data/mentor-menu-data";
import admin_menu_data from "../../data/admin-menu-data";

// Get the full hostname (including subdomain)
let fullHostname = window.location.hostname;

// Split the hostname into an array using dots as separators
let hostnameParts = fullHostname.split(".");

// The subdomain is the first part of the hostname
let subdomain = hostnameParts[0];

console.log("Current subdomain:", subdomain);

export const LOGO_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/logo/logo.png`;
export const HERO_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/brochures/1.png`;
export const ABOUT_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/about/1.png`;
export const WHY_URL = `https://mygreenhorn.in/WhiteLabelAssets/${subdomain}/why/1.png`;

const Header = ({ header_white, header_shadow }) => {
  const [Hostname, setHostname] = useState();
  const { sticky } = useSticky();
  const dispatch = useDispatch();
  const [logoUrl, setLogoURL] = useState();
  const [GuestMenuData, setGuestMenuData] = useState([]);
  const [StudentMenuData, setStudentMenuData] = useState([]);
  const [currentRole, setcurrentRole] = useState("GUEST");
  const [currentMenu, setcurrentMenu] = useState("menu_data");

  const _user = localStorage.getItem("user");

  // const getWhiteLabelConfig = useSelector((state) => state.whitelabel_config);
  // console.log("getWhiteLabelConfig", getWhiteLabelConfig);
  // const current_logo_url = getWhiteLabelConfig[0].logoURL;
  // console.log("current_logo_url", current_logo_url);

  const navigate = useNavigate();

  useEffect(() => {
    const Hostname = window.location.hostname;
    setHostname(Hostname);
    const res = getWhiteLabelConfig(Hostname)
      .then((data) => {
        console.log(data);
        //dispatch(GET_WHITELABEL_CONFIG(data));
        console.log("res", res);
        const current_logo_url = data[0].logoURL;
        const GuestMenuData = data[0].GuestMenuData;
        const StudentMenuData = data[0].StudentMenuData;
        console.log("GuestMenuData", GuestMenuData);
        console.log("StudentMenuData", StudentMenuData);
        setLogoURL(current_logo_url);
        //setGuestMenuData(GuestMenuData);
        //State Callback
        setGuestMenuData(GuestMenuData, () => {
          console.log("GuestMenuData", GuestMenuData);
        });
        setStudentMenuData(StudentMenuData, () => {
          console.log("StudentMenuData", StudentMenuData);
        });
        //setStudentMenuData(StudentMenuData);
      })
      .catch((err) =>
        // toast.error(`Unable to get White label Data. Contact US `, {
        //   position: "top-left",
        // })
        console.log("White Error", err)
      );
    setHostname(fullHostname);
    if (_user) {
      const user = JSON.parse(_user);
      const currentRoletemp = JSON.stringify(user.role);
      setcurrentRole(currentRoletemp);
    }
    console.log();
  }, [fullHostname]);

  useEffect(() => {
    const user = JSON.parse(_user);
    //console.log("user", user);
    if (user) {
      const currentRoletemp = user.role;
      //console.log("currentRole", currentRoletemp);
      setcurrentRole(currentRoletemp);
      if (currentRoletemp === "ADMIN") {
      } else if (currentRoletemp === "STUDENT") {
        setcurrentMenu(student_menu_data);
      }
    } else {
      //console.log("Reached Here");
      setcurrentRole("GUEST");
    }
  }, []);

  const handleLogout = () => {
    console.log("button clicked");
    localStorage.removeItem("user");
    localStorage.removeItem("user-details");
    setcurrentRole("GUEST");
    navigate("/student-login", { replace: true });
  };

  return (
    <>
      <header>
        <div
          id="header-sticky"
          className={`header__area 
        ${
          header_shadow
            ? "header__padding-2 header__shadow"
            : "header__transparent header__padding"
        } 
        ${header_white ? "header__white" : ""} ${sticky ? "sticky" : ""}`}
        >
          <div className={`${header_shadow ? "container" : "container-fluid"}`}>
            <div className="row align-items-center">
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                <div className="header__left d-flex">
                  <div className="logo">
                    <Link to="/">
                      <img width={120} src={LOGO_URL} alt="logo" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
                <div className="header__right d-flex justify-content-end align-items-center">
                  <div
                    className={`${
                      header_white
                        ? "main-menu main-menu-3 d-none d-xl-block"
                        : header_shadow
                        ? "main-menu main-menu-2 d-none d-xl-block"
                        : "main-menu d-none d-xl-block"
                    }`}
                  >
                    <nav id="mobile-menu">
                      {/* NavMenu start */}
                      {/* <NavMenu /> */}
                      {/* NavMenu end */}
                      {currentRole === "STUDENT" ? (
                        <ul>
                          <li>
                            <Link to=""></Link>
                          </li>
                          {StudentMenuData.map((link, index) => (
                            <li key={index} className="">
                              <Link to={`${link.MenuLink}`}>
                                {link.MenuTitle}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "ADMIN" ? (
                        <ul>
                          {admin_menu_data.map((link, index) => (
                            <li
                              key={index}
                              className={link.submenu ? "has-dropdown" : ""}
                            >
                              <Link to={`${link.link}`}>{link.title}</Link>
                              {link.submenu && (
                                <ul className="submenu">
                                  {link.submenu.map((menu) => (
                                    <li key={menu.id}>
                                      <Link to={`${menu.link}`}>
                                        {menu.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "EMPLOYER" ? (
                        <ul>
                          {recuiter_menu_data.map((link, index) => (
                            <li
                              key={index}
                              className={link.submenu ? "has-dropdown" : ""}
                            >
                              <Link to={`${link.link}`}>{link.title}</Link>
                              {link.submenu && (
                                <ul className="submenu">
                                  {link.submenu.map((menu) => (
                                    <li key={menu.id}>
                                      <Link to={`${menu.link}`}>
                                        {menu.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "MENTOR" ? (
                        <ul>
                          {mentor_menu_data.map((link, index) => (
                            <li
                              key={index}
                              className={link.submenu ? "has-dropdown" : ""}
                            >
                              <Link to={`${link.link}`}>{link.title}</Link>
                              {link.submenu && (
                                <ul className="submenu">
                                  {link.submenu.map((menu) => (
                                    <li key={menu.id}>
                                      <Link to={`${menu.link}`}>
                                        {menu.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : null}

                      {currentRole === "GUEST" ? (
                        <ul>
                          <Link to=""></Link>
                          {GuestMenuData.map((link, index) => (
                            <li key={index} className="">
                              <Link to={`${link.MenuLink}`}>
                                {link.MenuTitle}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </nav>
                  </div>

                  {header_shadow && (
                    <div className="header__btn header__btn-2 ml-50 d-none d-sm-block">
                      {currentRole == "GUEST" ? (
                        <Link to="/student-login" className="e-btn">
                          Sign in
                        </Link>
                      ) : null}

                      {currentRole !== "GUEST" ? (
                        <div onClick={() => handleLogout()} className="e-btn">
                          Logout
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="sidebar__menu d-xl-none">
                    <div
                      onClick={() => dispatch(menu_bar(true))}
                      className="sidebar-toggle-btn ml-30"
                      id="sidebar-toggle"
                    >
                      <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* sidebar start */}
      <Sidebar />
      {/* sidebar end */}
    </>
  );
};

export default Header;
